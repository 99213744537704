<template>
  <section class="desgin">

    <Loader :loading="showLoader" />
    <div class="row flex-grow">
      <div class="row flex-grow">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Banner</h4>
              <form @submit.prevent="editLocation" class="forms-sample">
                <div class="row">
                  <div class="col-md-12">
                    <div role="group" class="form-group">
                      <label class="d-block">Title</label>
                      <div>
                        <input type="text" placeholder="Title" v-model="location.title" class="form-control">
                      </div>
                    </div>
  
                  </div>
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Banner Image</label>
                      <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                        @vdropzone-success="bannerUpload">
                        <div class="dropzone-custom-content">
                          <div class="image-size-dropzone">
                            <div>
                              <img :src="`${imageURL}/${location.bannerSmall}`" class="show-img img-fluid" />
                            </div>
                          </div>
                          <div class="subtitle p-2">
                            <span>Change</span>
                            <span style="color: #eb2027"> Image</span>
                          </div>
                        </div>
                      </vue-dropzone>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Banner Image</label>
                      <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                        @vdropzone-success="bannerBigUpload">
                        <div class="dropzone-custom-content">
                          <div class="image-size-dropzone">
                            <div>
                              <img :src="`${imageURL}/${location.banner}`" class="show-img img-fluid" />
                            </div>
                          </div>
                          <div class="subtitle p-2">
                            <span>Change</span>
                            <span style="color: #eb2027"> Image</span>
                          </div>
                        </div>
                      </vue-dropzone>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn mr-2 btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Section 1</h4>
              <form @submit.prevent="editLocation" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Title</label>
                      <div>
                        <input type="text" placeholder="Title" v-model="location.sectionTitle" class="form-control">
                      </div>
                    </div>
                    <div role="group" class="form-group">
                      <label class="d-block">Description</label>
                      <div>
                        <textarea v-model="location.sectionDescription" class="form-control" rows="15"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Image</label>
                      <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                        @vdropzone-success="afterFileUpload">
                        <div class="dropzone-custom-content">
                          <div class="image-size-dropzone">
                            <div>
                              <img :src="`${imageURL}/${location.sectionImage}`" class="show-img img-fluid" />
                            </div>
                          </div>
                          <div class="subtitle p-2">
                            <span>Change</span>
                            <span style="color: #eb2027"> Thumbnail</span>
                          </div>
                        </div>
                      </vue-dropzone>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn mr-2 btn-primary">Submit</button>
              </form>
            </div>
          </div>

        </div>


      </div>
    </div>
  </section>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Loader from "../components/Loader";
import { sliderApi } from "../api";
const imageURL = process.env.VUE_APP_BASE_URL;
export default {
  name: 'location',
  components: {
    vueDropzone: vue2Dropzone, Loader,
  },
  data() {
    return {
      showLoader: false,
      imageURL,
      location: "",
      thumb: {
        url: `${imageURL}/upload`,
        maxFilesize: 1,
      },
    };
  },
  mounted() {
    this.LocationById();
  },
  methods: {

    async LocationById() {
      this.showLoader = true;
      const { data } = await sliderApi.getLocationById(`1`);
      this.location = data.data.data;
      this.showLoader = false;
    },

    bannerBigUpload(file, response) {
      this.location.banner = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    bannerUpload(file, response) {
      this.location.bannerSmall = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    
    afterFileUpload(file, response) {
      this.location.sectionImage = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    async editLocation() {
      this.showLoader = true;
      const data = await sliderApi.updateLocation(this.location._id, {
        ...this.location,
      });
      this.showLoader = true;
      this.LocationById();
      if (data.status == 200) {
        
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    },

  }
}
</script>
